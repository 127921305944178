<template>
  <div class="starting-page">
    <div class="logo">
      <img src="~@/assets/images/Main_Black.svg" alt />
    </div>
    <router-view />

    <div class="account-disclaimer" v-if="canShowSignUp">
      <a href="/" @click.prevent.stop="$router.push('/auth/signup')">{{
        $t('AUTH_COMMON_SIGN_UP')
      }}</a>
    </div>
    <div class="starting-footer">
      <a href>Popsters Inc. © 2024</a>
      <a href="mailto:support@lucmus.com">{{ $t('USR_CONT') }}</a>
      <a href="https://lucmus.com/docs/Privacy.pdf" target="_blank">{{ $t('USR_PRVC') }}</a>
      <a href="https://lucmus.com/docs/Terms.pdf" target="_blank">{{ $t('USR_TERMS') }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Auth",
  computed: {
    canShowSignUp() {
      if (this.$route.path == "/auth/signup") return false;
      return true;
    },
  },
};
</script>
